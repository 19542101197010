import { Col, Form, Icon,  Popconfirm, Row, Upload,Button, Tooltip, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import I18n from '../../common/I18n';
import { alertMessage, ellipseText, getFileIcon } from '../../common/Common';
import AppConfig from '../../config/AppConfig';
import DeleteIcon from './DeleteIcon';
import { isEmpty } from 'lodash';
import moment from "moment";

function PODfiles (props) {

  const { pods, openLightbox,baseIndexes = [], handleChange, deleteNavigation, config, type_of_order,pictureInfo, showSignature } = props;
  const [ podInfos, setPodInfos ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(pods)) {
      const podsByCode = pods.reduce((acc, pod) => {
        if (!acc[pod.pic_code]) {
          acc[pod.pic_code] = [];
        }
        acc[pod.pic_code].push(pod);
        return acc;
      }, {});
      setPodInfos(podsByCode);
    }
  }, [ pods ]);

  
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      if(file?.uid) {{
        onSuccess("ok");
      }}
      else{
        onSuccess("error");
        return;
      }
    }, 0);
  };

  const renderPod = (pod, index) => {
    const {
      pic_code,
      mandatory,
      mandatory_for,
      src,
      ack_id,
      isNew,
      id,
      pic_title,
      fileType,
      picName
    } = pod;
  
    const isSignature = pic_code === "signature";
    const isMandatory = mandatory === "true";
    const originalIndex = baseIndexes[index] ?? index;
  
    const imageNameFinder = !_.isEmpty(pictureInfo)? pictureInfo?.find((pic) => pic?.ack_id === ack_id) : [] ;
    const imageRecord = src ? src.split("/").pop() : "";

    const onlyFilename =  imageNameFinder?.fileName ? imageNameFinder?.fileName?.split(".")?.shift() : imageRecord?.split(".")?.shift();
    const capturedAt = _.get(imageNameFinder, 'captured_at', "");
    const capturedDate = capturedAt ? moment(capturedAt).format("MMM DD, YYYY HH:mm") : "";
    const fileNameExt =   imageNameFinder?.fileName ? imageNameFinder?.fileName?.split(".")?.pop() : imageRecord?.split(".")?.pop();
    const ext = fileNameExt?.split("-")?.[0];
  
  const uploadButton = (
    <div>
        <Icon type={loading ? "loading" : "plus"} />
        <div className="ant-upload-text textBold textCaptilize">
          {isMandatory ? <sup className="textRed">*</sup> : ""}
          Upload
        </div>
      </div>
    );
  
    if (src.length) {
      return (
        <div className="ant-upload-list-picture-card" key={ack_id}>
          <Row gutter={16}>
            <Col>
              <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                <div className="ant-upload-list-item-info">
                  <span>
                    <span
                      className="ant-upload-list-item-thumbnail"
                      rel="noopener noreferrer"
                    >
                      {fileType === "image" ? (
                        <img
                          src={src}
                          alt="Pod"
                          className="ant-upload-list-item-image"
                          // style={{ height: 200, width: 200 }}
                        />
                      ) : (
                        <div>
                          <Icon
                            type={getFileIcon(fileType)}
                            style={{
                              fontSize: 108,
                              // height: 200,
                              // width: 200,
                            }}
                            theme="twoTone"
                          />
                        </div>
                      )}
                    </span>
                  </span>
                </div>
                <span className="ant-upload-list-item-actions">
                  <Icon
                    type={fileType === "image" ? "eye" : "download"}
                    onClick={(event) =>
                      openLightbox(event, originalIndex, "filteredPods", "src")
                    }
                  />
                  <Popconfirm
                    placement="topRight"
                    title={I18n.t("messages.delete_confirm")}
                    onConfirm={() =>
                      deleteNavigation(ack_id, "pod_pics", isNew)
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon />
                    &nbsp;&nbsp;&nbsp;
                  </Popconfirm>
                </span>
              </div>
            </Col>
            <Col span={24}>
              {!isEmpty(onlyFilename) && (
                <Tooltip>
                  <span>
                    {ellipseText(onlyFilename, 12, true)}.{ext}
                  </span>
                </Tooltip>
              )}
              {/* &nbsp;&nbsp;
              {!isEmpty(capturedDate) && (
                <Tooltip title="Captured Time">
                  <p>{capturedDate}</p>
                </Tooltip>
              )} */}
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <Upload.Dragger
          key={index}
          name="avatar"
          listType="picture-card"
          className="unique-upload"
          showUploadList={false}
          onChange={(info) => {
            handleChange(info, pod)
          }}
          customRequest={dummyRequest}
          // beforeUpload={beforeUpload}
        >
          {uploadButton}
        </Upload.Dragger>
      );
    }
  };

  if (!Object.keys(podInfos).length) {
    return null;
  }

  const shouldShowSignature = () => {
    if (config && config.signature_order_type) {
        if (config.signature_order_type.toUpperCase() === 'BOTH') { 
          return true;
        }

        switch (config.signature_order_type.toUpperCase()) {
          case AppConfig.orderTypeLabels['R'].toUpperCase():
            return AppConfig.pickOrderTypes.includes( type_of_order.toUpperCase() );
          case AppConfig.orderTypeLabels['D'].toUpperCase():
            return AppConfig.deliveryOrderTypes.includes( type_of_order.toUpperCase() );
          default:
            return false;
      }
    }
    return false;
  }

  return (
    <>
    <Row type='flex' gutter={16}>
        { Object.keys(podInfos).map((key, podIndex) => {
          if (key === "signature") { return null; }
          const currentPods = podInfos[ key ];
          return (
            <Col key={`pod${key}${podIndex}`}>
              <Form.Item label={ key } required={ podInfos[ key ][ 0 ].mandatory === "true" }>
                <div className={`pod-file-container ${key}-pod-file-container`}>
                  { currentPods.map((pod, index) => {
                    return renderPod(pod, podIndex);
                  }) }
                </div>
              </Form.Item>
            </Col>
          );
        }) }
        {/* <Col key={`tender`}>
          <Form.Item label="Tender">
            <div className={`pod-file-container tender-pod-file-container`}>
              {podInfos["Tender"].map((pod, index) => {
                return renderPod(pod, index);
              })}
            </div>
          </Form.Item>
        </Col> */}
      </Row>

      { shouldShowSignature() && showSignature && (
        <Row type='flex' gutter={16}>
          { Object.keys(podInfos).map((key, podIndex) => {
            if (key !== "signature") { return null; }
            const currentPods = podInfos[ key ];
            return (
              <Col  key={`sign${key}${podIndex}`}>
                <Form.Item label={ key } required={ podInfos[ key ][ 0 ].mandatory === "true" }>
                  <div className={`pod-file-container ${key}-pod-file-container`}>
                    { currentPods.map((pod, index) => {
                      return renderPod(pod, podIndex);
                    }) }
                  </div>
                </Form.Item>
              </Col>
            );
          }) }
        </Row>
      )}  
     
    </>

  );
}

export default PODfiles;

PODfiles.propTypes = {
  pods: PropTypes.array,
  showSignature: PropTypes.bool,
  openLightbox: PropTypes.func,
  handleChange: PropTypes.func,
  deleteNavigation: PropTypes.func,
  baseIndexes: PropTypes.arrayOf(PropTypes.number),
};

PODfiles.defaultProps = {
  pods: [],
  showSignature: true,
  openLightbox: () => { },
  handleChange: () => { },
  deleteNavigation: () => { }
};